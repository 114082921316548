<template>
  <div>
    <div
      :class="{
        scroll: !scrollstatic,
        head: true,
      }"
    >
      <div class="head-logo">
        <img src="../../assets/img/logoblue.png" />
      </div>
      <div class="head-btn" @click="handleMouseEnter">
        <img src="../../assets/img/anni.png" />
      </div>
      <div
        class="head-drawer"
        v-on:click.stop="drawerclick"
        v-show="drawerStatic"
      >
        <div class="head-drawer-content">
          <router-link to="/">
            <div v-on:click.stop="router" class="home">
              首页
            </div></router-link
          >
          <router-link to="/">
            <div v-on:click.stop="router" class="server">
              产品服务
            </div></router-link
          >
          <router-link to="/">
            <div v-on:click.stop="router" class="solve">
              解决方案
            </div></router-link
          >
          <router-link to="/about" class="about">
            <div v-on:click.stop="router">关于我们</div></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      scrollstatic: true,
      drawerStatic: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.windowScrollListener);
  },
  mounted() {},
  methods: {
    drawerclick() {
      console.log(1);
      this.drawerStatic = false;
    },
    router() {
      console.log(2);

      this.drawerStatic = false;
    },
    handleMouseEnter() {
      console.log("移入");
      this.drawerStatic = true;
    },
    handleMouseLeave() {
      console.log("移出 ");
      this.drawerStatic = false;
    },
    windowScrollListener() {
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 600) {
        this.scrollstatic = false;
      }
      if (scrollTop < 600) {
        this.scrollstatic = true;
        this.drawerStatic = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.scroll {
  position: fixed !important;
  background-color: #ffffff !important;
}
.head {
  width: 24rem;
  height: 3.584rem;
  position: absolute;
  top: 0;
  z-index: 99999;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  .head-logo {
    width: 3.328rem;
    height: 2.816rem;
    position: absolute;
    left: 1.408rem;
    top: 0.768rem;
    img {
      width: 3.328rem;
      height: 2.816rem;
    }
  }
  .head-btn {
    position: absolute;
    right: 1.152rem;
    top: 1.4rem;

    img {
      width: 1.28rem;
      height: 1.28rem;
    }
  }
}
.head-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .head-drawer-content {
    background-image: url(../../assets/img/qiehuanmokuai.png);
    background-size: cover;
    position: absolute;
    z-index: 99999999;
    top: 3.2rem;
    right: 1.6rem;
    width: 8.96rem;
    height: 14.464rem;
    padding: 0.96rem 1.12rem;
    font-size: 0.896rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .server {
    padding-top: 1.024rem;
    padding-bottom: 1.024rem;
    width: 7.04rem;
    border-top: 0.032rem solid #dddddd;
    border-bottom: 0.032rem solid #dddddd;
    color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .solve {
    padding-top: 1.024rem;
    padding-bottom: 1.024rem;
    width: 7.04rem;
    border-bottom: 0.032rem solid #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #cccccc;
  }
  .about {
    margin-top: .64rem;
  }
  .home {
    margin-top: .64rem;
    margin-bottom: .64rem;

  }
}
</style>
