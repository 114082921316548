<template>
  <div class="about-banner">
    <div class="banner-middle">
      <div class="banner-middle-one">
        <p>
          关于我们
        </p>
      </div>
      <div class="banner-middle-two">
        <p>
          专注于跨境物流服务,为合作伙伴提供极致物流体验
        </p>
      </div>
    </div>
    <div class="banner-footer">
      <div class="banner-footer-img">
        <img src="./../../../assets/images/abc.png" alt="" />
      </div>
      <div class="banner-footer-text-one">
        <div class="banner-footer-text-one-title">起东供应链公司</div>
        <div class="line"></div>
      </div>
      <div class="banner-footer-text-two">
        <p>
          公司使命：秉承客户至上的理念，全力打造卓越无比的物流平台，巧妙整合全球资源，为客户提供多元化、无与伦比的供应链物流服务。
          <br />
        </p>
        <br />
        <p>
          业务范围：供应链物流，我们致力于为客户提供全球物流运输的一站式多元化供应链物流服务，以出色的仓储集货、定制航线订舱、高效报关、实时货物跟踪等环节为客户提供无微不至的关怀。
          <br />
        </p>
      </div>
    </div>
    <div class="firm">
      <p>企业文化</p>
      <span class="line"></span>
      <div class="firm-img">
        <div class="firm-img-one">
          <p>
            打造卓越的物流服务平台，整合全球物流资。为客户提供多元化、稳定高效的供应链物流服务
          </p>
          <div class="firm-img-one-a">
            <p>使命</p>
          </div>
        </div>
        <div class="firm-img-two">
          <p>整合创新、服务至上、卓越出众</p>
          <div class="firm-img-two-a">
            <p>价值观</p>
          </div>
        </div>
        <div class="firm-img-three">
          <p>
            打造卓越的物流服务平台，整合全球物流资。为客户提供多元化、稳定高效的供应链物流服务
          </p>
          <div class="firm-img-three-a">
            <p>愿景</p>
          </div>
        </div>
      </div>
    </div>
    <div class="contact">
      <p>联系我们</p>
      <span></span>
    </div>

    <div class="map">
      <img src="./../../../assets/images/map.png" alt="" />
      <div class="map-text">
        <!-- 更改地址部分 -->
        <a
          href="https://uri.amap.com/marker?position=114.085349,22.663525&name=科尔达大厦&src=mypage&coordinate=gaode&callnative=1"
          >深圳市龙岗区坂田街道科尔达大厦907</a
        >
      </div>
      <div class="map-map">
        <img src="./../../../assets/images/address.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    hover(index) {
      this.active = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.about-banner {
  height: 2800px;
  width: 100%;
  background: #f4f4f4 url(../../../assets/images/keystone.png) no-repeat top
    center;
  position: relative;
  margin: 0 auto;
}
.banner-middle {
  margin-top: 246px;
  position: absolute;
  width: 60%;
  height: 220px;
  left: 0;
  background: #234dd3;
  opacity: 0.8;
}

.banner-middle-one {
  width: 144px;
  height: 50px;
  font-size: 36px;
  margin-left: 24%;
  margin-top: 51px;
  p {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    line-height: 50px;
  }
}
.banner-middle-two {
  width: 579px;
  height: 37px;
  font-size: 36px;
  margin-left: 24%;
  margin-top: 36px;
  p {
    font-size: 26px;
    font-weight: 400;
    color: #ffffff;
    line-height: 37px;
  }
}

.banner-footer {
  background: #ffffff;
  position: absolute;
  margin-left: 15.36%;
  margin-top: 694px;
  width: 69.27%;
  height: 320px;
  p {
    margin-top: 47px;
    margin-left: 51.58%;
    width: 11%;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
}

.banner-footer-img {
  position: absolute;
  width: 49.17%;
  height: 320px;
  left: 0;
}

.line {
  display: flex;
  width: 30px;
  height: 4px;
  background: #234dd3;
  margin-top: 20px;
}

.banner-footer-text-two {
  position: absolute;
  right: 31px;
  top: 80px;
}

.banner-footer-text-two p {
  text-indent: 2em;
  margin-top: 35px;
  margin-left: 51.58%;
  width: 42.33%;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
}
.banner-footer-text-one {
  position: absolute;
  right: 33%;
  top: 35px;
}
.banner-footer-text-one-title {
  font-size: 26px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
}

.firm {
  margin-top: 1020px;
  width: 100%;
  height: 1591px;
  position: absolute;
}

.firm p {
  margin-left: 47.3%;
  margin-top: 69px;
  width: 104px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #333333;
  line-height: 37px;
}

.firm span {
  margin-left: 49%;
  display: flex;
  width: 30px;
  height: 4px;
  background: #234dd3;
}

.firm-img {
  margin-top: 72px;
  display: flex;
}

.firm-img-one,
.firm-img-two,
.firm-img-three {
  border-radius: 8px;
  height: 480px;
  width: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.firm-img-one {
  margin-left: 20%;
  background-image: url("./../../../assets/images/buttom.png");
}

.firm-img-two {
  margin-left: 5.3%;
  background-image: url("./../../../assets/images/people.png");
}

.firm-img-three {
  margin-left: 5.3%;
  background-image: url("./../../../assets/images/hand.png");
}

.firm-img-one p {
  margin-top: 280px;
  margin-left: 39px;
  width: 215px;
  height: 120px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  position: absolute;
}

.firm-img-two p {
  margin-top: 310px;
  margin-left: 39px;
  width: 224px;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  color: #ffffff;
  line-height: 22px;
}

.firm-img-three p {
  margin-top: 290px;
  position: absolute;
  margin-left: 39px;
  width: 224px;
  height: 60px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}

.firm-img-one-a {
  margin-top: -284px;
  margin-left: 81px;
}

.firm-img-two-a {
  margin-top: -260px;
  margin-left: 81px;
}

.firm-img-three-a {
  margin-top: -260px;
  margin-left: 81px;
}

.firm-img-one-a p {
  width: 52px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  line-height: 37px;
  bottom: 35px;
  position: absolute;
}

.firm-img-two-a p {
  bottom: 0;
  position: absolute;
  width: 78px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  bottom: 35px;

  line-height: 37px;
}

.firm-img-three-a p {
  width: 52px;
  bottom: 0;
  position: absolute;
  height: 37px;
  font-size: 26px;
  bottom: 35px;

  font-weight: bold;
  color: #ffffff;
  line-height: 37px;
}

.firm-img-one,
.firm-img-two,
.firm-img-three {
  position: relative;
}

.firm-img-one::before,
.firm-img-two::before,
.firm-img-three::before {
  border-radius: 8px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(162deg, rgba(45, 86, 214, 0.1) 0%, #000a2b 100%);
  /* 替换背景色覆盖层的颜色 */
  /* mix-blend-mode: screen; */
  /* 使用screen混合模式 */
  /* opacity: 0; */
  pointer-events: none;
  /* 防止覆盖层阻碍鼠标事件 */
  transition: opacity 0.3s ease;
  /* 添加过渡效果 */
}

.firm-img-one:hover {
  transform: scale(1.1); /* 鼠标移入，放大1.2倍 */
  transition: all 0.5s ease-in-out; /* 添加过渡动画，效果持续0.5秒，缓动效果 */
}
.firm-img-two:hover {
  transform: scale(1.1); /* 鼠标移入，放大1.2倍 */
  transition: all 0.5s ease-in-out; /* 添加过渡动画，效果持续0.5秒，缓动效果 */
}
.firm-img-three:hover {
  transform: scale(1.1); /* 鼠标移入，放大1.2倍 */
  transition: all 0.5s ease-in-out; /* 添加过渡动画，效果持续0.5秒，缓动效果 */
}
.firm-img-one:hover::before,
.firm-img-two:hover::before,
.firm-img-three:hover::before {
  /* opacity: 1; */
  transform: scale(1); /* 鼠标移入，放大1.2倍 */
  transition: all 0.5s ease-in-out; /* 添加过渡动画，效果持续0.5秒，缓动效果 */
  border-radius: 8px;
  background: linear-gradient(162deg, rgba(45, 86, 214, 0.4) 0%, #001a6f 100%);
}

.contact {
  margin-top: 1800px;
  margin-left: 46.7%;
  position: absolute;
  p {
    width: 120px;
    height: 42px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
  }
  span {
    margin-left: 30%;
    display: flex;
    width: 40px;
    height: 4px;
    background: #234dd3;
    margin-top: 15px;
  }
}
.map {
  position: absolute;
  margin-top: 1925px;
  margin-left: 18.8%;
  width: 1200px;
  height: 677px;
  background: #ffffff;
  img {
    position: absolute;
    margin-top: 28px;
    margin-left: 6.3%;
    width: 87.3%;
    height: 550px;
  }
}

.map-text {
  position: absolute;
  background: url(../../../assets/images/wenzijiji.png);
  background-size: cover;
  font-size: 36px;
  margin-left: 33%;
  margin-top: 190px;
  width: 308px;
  height: 72px;
  line-height: 47px;
  opacity: 0.8;
  a {
    margin-top: 22px;
    margin-left: 20px;
    width: 268px;
    height: 22px;
    font-weight: 400;
    color: #ffffff !important;
    line-height: 22px;
    font-size: 16px;
  }
}

.map-text a:hover {
  color: #fd7e14;
  /* 设置链接悬停时的字体颜色 */
  text-decoration: none;
  /* 移除链接悬停时的下划线效果 */
}

.map-map {
  position: absolute;
  margin-top: 233px;
  margin-left: 45%;
  img {
    width: 30px;
    height: 35px;
  }
}
</style>
