<template>
  <div>
    <div class="footer-left">
      <img src="../../assets/images/logo2.png" />
    </div>
    <div class="footer-middle">
      <div class="footer-middle-phone">
        <img src="../../assets/images/phone2.png" alt="" />
        <p>400-097-0790</p>
      </div>
      <div class="footer-middle-address">
        <img src="../../assets/images/address2.png" alt="" />
        <p>深圳市龙岗区坂田街道象角塘社区中浩一路2号科尔达大厦907室</p>
      </div>
      <div class="footer-middle-email">
        <img src="../../assets/images/email2.png" alt="" />
        <p>info@keystonescm.com</p>
      </div>
      <div class="footer-middle-photo">
        <img src="../../assets/images/public-account.png" alt="" />
      </div>
      <div class="footer-text">
        <span>关注公众号，了解最新资讯</span>
      </div>
    </div>
    <div class="footer-right-one">
      <p>关于起东</p>
    </div>
    <div class="footer-right-two">
      <router-link to="/"> 公司介绍 </router-link>
    </div>
    <div class="footer-right-three">
      <p>联系我们</p>
    </div>
    <div class="extend">
      <div class="extend-text">
        <p>了解更多跨境物流解决方案</p>
      </div>
      <div class="extend-button">
        <span>立即咨询</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 620px;
  background: #222222;
  position: relative;
}

.footer-left {
  width: 5.7%;
  height: 109px;
  margin-top: 157px;
  margin-left: 21.1%;
  position: absolute;
}

.footer-middle {
  position: absolute;
}

.footer-middle-phone {
  margin-left: 578px;
  margin-top: 190px;
  display: flex;
  align-items: center;
}

.footer-middle-phone p {

  opacity: 0.8;
  margin-left: 15px;
  margin-top: 2px;
  margin-bottom: 16px!important;

  width: 101px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  position: relative;
  top: 6px;
}

.footer-middle-address {
  margin-left: 578px;
  display: flex;
  align-items: center;
}

.footer-middle-address p {
  margin-bottom: 16px!important;

  opacity: 0.8;
  margin-left: 15px;
  margin-top: 2px;
  width: 397px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  position: relative;
  top: 17px;
}

.footer-middle-email {
  margin-left: 578px;
  display: flex;
  align-items: center;
}

.footer-middle-email p {
  margin-bottom: 16px!important;

  opacity: 0.8;
  margin-left: 15px;
  width: 154px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  position: relative;
  top: 6px;
}

.footer-middle-photo {
  margin-left: 596px;
  display: flex;
  align-items: center;
}

.footer-middle-photo img {
  margin-top: 40px;
  margin-left: 20px;
}

.footer-middle-photo p {
  width: 101px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  top: 6px;
}

.footer-text {
  position: absolute;
  margin-left: 600px;
  margin-top: 31px;
}

.footer-text span {
  opacity: 0.8;
  width: 168px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}

.footer-right-one {
  position: absolute;
  margin-left: 66.67%;
  margin-top: 190px;
}

.footer-right-one p {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
}

.footer-right-two {
  position: absolute;
  margin-left: 67.14%;
  margin-top: 252px;
}

.footer-right-two a {
  opacity: 0.8;
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff !important;
  line-height: 20px;
}

.footer-right-three {
  position: absolute;
  margin-left: 67.14%;
  margin-top: 312px;
}

.footer-right-three p {
  opacity: 0.8;
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}

.extend {
  width: 100%;
  height: 100px;
  background: #234dd3;
  display: flex;
  align-items: center;
}

.extend-text {
  margin-left: 38%;
  width: auto;
  height: 25px;
}

.extend-text p {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 25px;
  margin-right: 47px;
}

.extend-button {
  width: 110px;
  height: 44px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #234dd3;
}

.extend-button span {
  width: 110px;
  height: 44px;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  color: #234dd3;
  line-height: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
