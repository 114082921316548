<template>
  <div>
    <div class="about-ban">
      <div class="about-contnent" data-aos="fade-right">
        <div class="about-contnent-title">
          <div class="title-top">关于我们</div>
          <div class="title-bootm">
            专注于跨境物流服务,为合作伙伴提供极致物流体验
          </div>
        </div>
      </div>
    </div>
    <div class="about-sub">
      <div class="about-qidong">
        <div class="about-qidong-left"></div>
        <div class="about-qidong-right">
          <div class="about-qidong-title">
            <div class="about-qidong-con">起东供应链公司</div>
            <div class="about-qidong-line"></div>
          </div>
          <div class="about-qidong-text">
            <div class="about-qidong-text-top">
              公司使命：秉承客户至上的理念，全力打造卓越无比的物流平台，巧妙整合全球资源，为客户提供多元化、无与伦比的供应链物流服务。
            </div>

            <div class="about-qidong-text-bootom">
              业务范围：供应链物流，我们致力于为客户提供全球物流运输的一站式多元化供应链物流服务，以出色的仓储集货、定制航线订舱、高效报关、实时货物跟踪等环节为客户提供无微不至的关怀。
            </div>
          </div>
        </div>
      </div>
      <div class="about-describe">
        <div class="about-describe-tltle">
          <div class="about-describe-text">企业文化</div>
          <div class="about-describe-line"></div>
        </div>
        <div class="about-describe-mission" @click="hover(1)">
          <div
            :class="{
              missionback: activeplan == 1,
              'about-describe-mission-back': true,
            }"
            class="about-describe-mission-back"
          >
            <div class="about-describe-mission-title">使命</div>
            <div>
              <div class="about-describe-mission-text">
                打造卓越的物流服务平台，整合全球物流资。为
              </div>
              <div class="about-describe-mission-text">
                客户提供多元化、稳定高效的供应链物流服务。
              </div>
            </div>
          </div>
        </div>
        <div class="about-describe-values" @click="hover(2)">
          <div
            :class="{
              valuesback: activeplan == 2,
              'about-describe-values-back': true,
            }"
          >
            <div class="about-describe-values-title">价值观</div>
            <div class="about-describe-values-text">
              整合创新、服务至上、卓越出众
            </div>
          </div>
        </div>
        <div class="about-describe-Vision" @click="hover(3)">
          <div
            class="about-describe-Vision-back"
            :class="{
              Visionback: activeplan == 3,
              'about-describe-Vision-back': true,
            }"
          >
            <div class="about-describe-Vision-title">愿景</div>
            <div class="about-describe-Vision-text">
              成为全球供应链物流领域的引领者，一流的供应链物流服务。
            </div>
          </div>
        </div>
        <div class="about-describe-contact">
          <div class="about-describe-contact-title">联系我们</div>
          <div class="about-describe-contact-line"></div>
        </div>
      </div>
      <div class="contact-map">
        <div class="map-img">
          <div class="contact-map-end">
            <div class="contact-map-content">
              <a
                href="https://uri.amap.com/marker?position=114.085349,22.663525&name=科尔达大厦&src=mypage&coordinate=gaode&callnative=1"
              >
                深圳市龙岗区坂田街道科尔达大厦907</a
              >
            </div>
            <div class="contact-map-loacl">
              <img src="../../../assets/img/dingwei.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      activeplan: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    hover(index) {
      this.activeplan = index;
    },
  },
};
</script>
<style lang="scss">
.about-ban {
  width: 24rem;
  background-image: url(../../../assets/img/juxing.png);
  height: 18.56rem;
  background-size: cover;
  position: relative;
  .about-contnent {
    width: 21.504rem;
    height: 5.76rem;
    background: #234dd3;
    opacity: 0.8 !important;
    position: absolute;
    top: 5.9rem;
    left: 0px;
    .about-contnent-title {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      font-size: 0.832rem;
      margin-top: 1.28rem;
      margin-left: 1.536rem;
      .title-top {
        font-size: 0.96rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.344rem;
      }
      .title-bootm {
        margin-top: 0.32rem;
        font-size: 0.832rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
  }
}

.about-sub {
  background-color: #f4f4f4;
  position: relative;
  .about-qidong {
    position: relative;
    right: -1.28rem;
    top: -3.52rem;
    width: 22.72rem;
    height: 13.976rem;
    display: flex;
    .about-qidong-left {
      background-image: url(../../../assets/img/beij.png);
      background-size: cover;
      width: 7.232rem;
      height: 13.976rem;
    }
    .about-qidong-right {
      width: 15.488rem;
      height: 13.976rem;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .about-qidong-title {
        position: absolute;
        top: 0.576rem;
        left: 0.896rem;
      }
      .about-qidong-con {
        font-size: 0.896rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 1.92rem;
      }
      .about-qidong-line {
        width: 1.28rem;
        height: 0.128rem;
        background: #234dd3;
      }
      .about-qidong-text {
        width: 12.672rem;
        margin-top: 2.8rem;
        height: 9.856rem;
        font-size: 0.704rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.896rem;
        text-indent: 1.92rem;
        .about-qidong-text-top {
          margin-top: 0.64rem;
          margin-bottom: 0.64rem;
          width: 12.672rem;
        }
        .about-qidong-text-bootom {
          width: 12.672rem;
        }
      }
    }
  }
  .about-describe {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -1.92rem;
    .about-describe-tltle {
      display: flex;
      flex-direction: column;
      align-items: center;
      .about-describe-text {
        font-size: 0.896rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 1.28rem;
        margin-bottom: 0.64rem;
      }
      .about-describe-line {
        width: 1.28rem;
        height: 0.128rem;
        background: #234dd3;
      }
    }
    .about-describe-mission {
      width: 20.16rem;
      height: 12.16rem;
      background-image: url(../../../assets/img/shiming.png);
      background-size: cover;
      margin-top: 1.92rem;
      margin-bottom: 1.28rem;
      border-radius: 0.16rem;
      .missionback {
        background: linear-gradient(
          162deg,
          rgba(45, 86, 214, 0.4) 0%,
          #001a6f 100%
        ) !important;
      }
      .about-describe-mission-back {
        width: 20.16rem;
        height: 12.16rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border-radius: 0.16rem;
        background: linear-gradient(
          162deg,
          rgba(0, 10, 42, 0.4) 0%,
          #000a2b 100%
        );
        opacity: 0.8;

        .about-describe-mission-title {
          font-size: 1.152rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          line-height: 1.6rem;
        }
        .about-describe-mission-text {
          font-size: 0.768rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 1.92rem;
        }
      }
    }
    .about-describe-values {
      width: 20.16rem;
      height: 12.16rem;
      background-image: url(../../../assets/img/jiazhiguan.png);
      background-size: cover;
      margin-bottom: 1.28rem;

      border-radius: 0.16rem;
      .valuesback {
        background: linear-gradient(
          162deg,
          rgba(45, 86, 214, 0.4) 0%,
          #001a6f 100%
        ) !important;
      }
      .about-describe-values-back {
        width: 20.16rem;
        height: 12.16rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border-radius: 0.16rem;
        background: linear-gradient(
          162deg,
          rgba(0, 10, 42, 0.4) 0%,
          #000a2b 100%
        );
        opacity: 0.8;

        .about-describe-values-title {
          font-size: 1.152rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          line-height: 1.6rem;
        }
        .about-describe-values-text {
          font-size: 0.768rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 1.92rem;
        }
      }
    }
    .about-describe-Vision {
      width: 20.16rem;
      height: 12.16rem;
      background-image: url(../../../assets/img/yuanjing.png);
      background-size: cover;
      margin-bottom: 1.28rem;
      .Visionback {
        background: linear-gradient(
          162deg,
          rgba(45, 86, 214, 0.4) 0%,
          #001a6f 100%
        ) !important;
      }
      .about-describe-Vision-back {
        width: 20.16rem;
        height: 12.16rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border-radius: 0.16rem;
        background: linear-gradient(
          162deg,
          rgba(0, 10, 42, 0.4) 0%,
          #000a2b 100%
        );
        opacity: 0.8;

        .about-describe-Vision-title {
          font-size: 1.152rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          line-height: 1.6rem;
        }
        .about-describe-Vision-text {
          width: 16.32rem;
          height: 3.84rem;
          font-size: 0.768rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 1.92rem;
        }
      }
    }
  }
  .about-describe-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-describe-contact-title {
      font-size: 0.896rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 1.28rem;
    }
    .about-describe-contact-line {
      width: 1.28rem;
      height: 0.128rem;
      background: #234dd3;
      margin-top: 1.28rem;
      margin-bottom: 1.28rem;
    }
  }
  .contact-map {
    width: 24rem;
    height: 14.144rem;
    background: #ffffff;
    padding: 1.408rem;
    .map-img {
      width: 21.312rem;
      height: 11.456rem;
      background-image: url(../../../assets/img/map.png);
      background-size: cover;
      position: relative;
    }
    .contact-map-end {
      position: absolute;
      top: 1.8rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: 4rem;
      .contact-map-content {
        width: 14.592rem;
        height: 3.2rem;
        line-height: 2.6rem;
        display: flex;
        background-size: cover;
        justify-content: center;
        background-image: url(../../../assets/img/dizhibeijing.png);
        font-size: 0.768rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        a {
          color: #ffffff !important;
        }
      }
    }
  }
}
.contact-map-loacl {
  width: 1rem;
  height: 1rem;
  img {
    width: 1rem;
    height: 1.1rem;
  }
}
</style>
