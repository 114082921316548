<template>
  <div>
    <div class="home-banner">
      <div class="consult" data-aos="fade-right">
        <p>全链路一站式智能跨境物流服务平台</p>
        <div class="consult-seek">立即咨询</div>
        <div class="consult-input">
          <input placeholder="输入货物追踪单号" />
          <span>查询</span>
        </div>
      </div>
      <div class="banner-bottom">
        <div class="banner-bottom-list" data-aos="fade-up">
          <li @mouseenter="hover(1)">
            <img src="../../../assets/images/InternationalFreight.png" alt="" />
            <span>国际货运</span>
          </li>
          <li @mouseenter="hover(2)">
            <img src="../../../assets/images/customsClearance.png" alt="" />
            <span>清关</span>
          </li>
          <li @mouseenter="hover(3)" class="hoverthere">
            <img src="../../../assets/images/overseasWarehouse.png" alt="" />
            <span>仓储</span>
          </li>
          <li @mouseenter="hover(4)" class="hoverfore">
            <img
              src="../../../assets/images/globalFreightForwarding.png"
              alt=""
            />
            <span>货物代运</span>
          </li>
          <li @mouseenter="hover(5)" class="hoverfive">
            <img src="./../../../assets/images/materialFlow.png" alt="" />
            <span>快递</span>
          </li>
          <div
            :style="{
              left: leftnum,
            }"
            class="anima"
          ></div>

          <div class="banner-bottom-list-item-content">
            <div
              class="banner-bottom-list-item-content-inner"
              v-show="active == 1"
            >
              <span>FBX全程物流</span>
              <p>
                起东国际供应链致力于成为全球领先的物流服务提供商。多年来，我们与众多客户合作，建立了强大的网络和丰富的物流经验。我们的专业团队和先进的技术保证了我们在物流行业的领先地位。
              </p>
            </div>
            <div
              class="banner-bottom-list-item-content-inner"
              v-show="active == 2"
            >
              <span>起东全球关务</span>
              <p>
                起东全球关务服务是起东国际供应链的专业关务服务部门。我们汇聚了一支经验丰富、专业可靠的关务团队，致力于为客户提供全面的跨境贸易支持和定制化的关务服务。
              </p>
            </div>

            <div
              class="banner-bottom-list-item-content-inner"
              v-show="active == 3"
            >
              <span>起东海外仓</span>
              <p>
                起东海外仓是起东国际供应链的海外仓储服务产品。我们为客户提供高效、安全、灵活的海外仓储解决方案，帮助您更好地管理库存、降低物流成本，并加速您的全球业务发展。
              </p>
            </div>
            <div
              class="banner-bottom-list-item-content-inner"
              v-show="active == 4"
            >
              <span>起东全球货代</span>
              <p>
                起东全球货代服务是起东国际供应链的专业货运代理服务部门。我们拥有丰富的国际货运经验和全球合作伙伴网络，致力于为客户提供一流的货代服务，无论是海运、空运还是陆运，我们都能为您提供全程支持。
              </p>
            </div>
            <div
              class="banner-bottom-list-item-content-inner"
              v-show="active == 5"
            >
              <span>起东商业快件KeyEx</span>
              <p>
                起东商业快件服务是起东国际供应链的专业快递服务部门。我们拥有广泛的网络和先进的运输技术，致力于为客户提供高效、安全的商业快递服务，满足您的快递运输需求。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-network">
      <div class="network-sw">
        <div class="network-top">
          <div>丰富的</div>
          <div>起东全球网络</div>
          <div class="line"></div>
        </div>
        <!--轮播-->
        <div class="plan-container">
          <div class="plan-type">
            <div class="plan-type-left">
              <div @click="clickplan(1)" class="plan-type-left-card">
                <div>亚洲地区</div>
              </div>
              <div @click="clickplan(2)" class="plan-type-left-card">
                <div>欧洲地区</div>
              </div>
              <div @click="clickplan(3)" class="plan-type-left-card">
                <div>北美地区</div>
              </div>
              <div :style="{ top: plantop }" class="plan-type-active">
                <div>{{ area }}</div>
              </div>
            </div>
            <div class="plan-type-right">
              <div
                :class="{
                  active: activeplan == 1,
                  'plan-type-right-card': true,
                }"
              >
                <a class="plan-type-right-card-link" data-tab="0">
                  <img src="../../../assets/images/asia.jpg" alt="" />
                  <div class="plan-type-right-inner ">
                    <p>
                      作为总部所在地的中国，起东国际供应链在中国内地拥有深圳、青岛、北京、上海、成都的物流网络覆盖。同时，我们在亚洲其他国家和地区，如日本、韩国等也设有物流合作伙伴，以满足不同地区客户的需求。
                    </p>
                  </div>
                  <div class="plan-type-right-tabs">
                    <span>1对1专业出口顾问服务</span>
                    <span>缩短50%以上准备时间</span>
                    <span>全流程指导</span>
                  </div>
                </a>
              </div>
              <div
                :class="{
                  active: activeplan == 2,
                  'plan-type-right-card': true,
                }"
              >
                <a class="plan-type-right-card-link" data-tab="1">
                  <img src="../../../assets/images/europe.jpg" alt="" />
                  <div class="plan-type-right-inner">
                    <p>
                      起东国际供应链在欧洲各主要国家建立了广泛的物流网络。我们拥有物流中心和分支机构，涵盖了比利时、德国、法国、意大利、波兰等欧洲主要经济体。通过这些网络，我们能够为客户提供全面的物流服务，满足他们在欧洲市场的需求。
                    </p>
                  </div>
                  <div class="plan-type-right-tabs">
                    <span>1对1专业出口顾问服务</span>
                    <span>缩短50%以上准备时间</span>
                    <span>全流程指导</span>
                  </div>
                </a>
              </div>
              <div
                :class="{
                  active: activeplan == 3,
                  'plan-type-right-card': true,
                }"
              >
                <a class="plan-type-right-card-link" data-tab="2">
                  <img src="../../../assets/images/northAmerica.jpg" alt="" />
                  <div class="plan-type-right-inner">
                    <p>
                      起东国际供应链在北美地区建立了强大的物流网络。我们在美国东部和西部设有办事处和仓储设施，以支持客户在北美的物流需求。通过合作伙伴关系，我们还能够覆盖到整个北美市场。
                    </p>
                  </div>
                  <div class="plan-type-right-tabs">
                    <span>1对1专业出口顾问服务</span>
                    <span>缩短50%以上准备时间</span>
                    <span>全流程指导</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="network-foot">
        <div class="network-foot-font">了解更多行业定制方案？</div>
        <div class="network-foot-btn">立即咨询</div>
      </div>
    </div>
    <div class="territory">
      <div class="territory-first" data-aos="fade-right">
        <img src="../../../assets/images/first.png" alt="" />
      </div>
      <p>
        引领行业
      </p>
      <span class="line"></span>

      <div class="territory-dalou" data-aos="fade-left">
        <img src="../../../assets/images/block.png" alt="" />
      </div>
    </div>
    <div class="mileage">
      <div class="mileage-top" style="background: rgba(38, 78, 194, 0.3);">
        <div class="mileage-top-one">
          <p>一路走来</p>
        </div>
        <span class="line"></span>
        <div class="mileage-top-two">
          <p>的里程</p>
        </div>
      </div>
      <div class="mileage-top-three">
        <div class="mileage-top-three-time">
          <p>2015</p>
        </div>
        <div class="mileage-top-three-text">
          <p>创立于比利时维塞 <br />Start from Vise, Belgium</p>
        </div>
      </div>
      <div class="mileage-top-four">
        <div class="mileage-top-four-time">
          <p>2016</p>
        </div>
        <div class="mileage-top-four-text">
          <p>
            获得比利时自营关务及海关保税仓牌照 <br />Customs Clearance Agent &
            Bonded Warehouse License in Belgium
          </p>
        </div>
      </div>
      <div class="mileage-top-five">
        <div class="mileage-top-five-time">
          <p>2017</p>
        </div>
        <div class="mileage-top-five-text">
          <p>
            总部迁往比利时列日机场<br />Headquarter Moved to LGG Airport,
            Belgium
          </p>
        </div>
        <div class="mileage-top-five-text-two">
          <p>中国深圳分公司成立<br />Shenzhen Branch, China</p>
        </div>
      </div>
      <div class="mileage-middle-one">
        <div class="mileage-middle-one-time">
          <p>2018</p>
        </div>
        <div class="mileage-middle-one-text-one">
          <p>
            获得比利时年度最佳物流项目奖 <br />
            Awarded as Project of the year of Belgium
          </p>
        </div>
        <div class="mileage-middle-one-text-two">
          <p>
            航空货代分公司在比利时列日成立 <br />
            Airfreight Forwarder Branch at LGG, Belgium
          </p>
        </div>
      </div>
      <div class="mileage-middle-two">
        <div class="mileage-middle-two-time">
          <p>2019</p>
        </div>
        <div class="mileage-middle-two-text-one">
          <p>
            第一辆自营卡车 <br />
            First Truck
          </p>
        </div>
        <div class="mileage-middle-two-text-two">
          <p>
            德国法兰克福分公司成立 <br />
            German Branch at Frankfurt, Germany
          </p>
        </div>
      </div>
      <div class="mileage-middle-three">
        <div class="mileage-middle-three-time">
          <p>2020</p>
        </div>
        <div class="mileage-middle-three-text-one">
          <p>
            中国青岛分公司成立 <br />
            Qingdao Branch, China
          </p>
        </div>
      </div>

      <div class="mileage-bottom-one">
        <div class="mileage-bottom-one-time">
          <p>2021</p>
        </div>
        <div class="mileage-bottom-one-text-one">
          <p>
            美国洛杉矶分公司成立 <br />
            American Branch at Los Angeles, USA
          </p>
        </div>
        <div class="mileage-bottom-one-text-two">
          <p>
            自营卡车公司在比利时列日成立 <br />Transport Branch at Liege,
            Belgium
          </p>
        </div>
      </div>
      <div class="mileage-bottom-two">
        <div class="mileage-bottom-two-time">
          <p>2022</p>
        </div>
        <div class="mileage-bottom-two-text-one">
          <p>
            新加坡分公司成立 <br />
            Singaporean Branch, Singapore
          </p>
        </div>
      </div>
      <div class="mileage-bottom-three">
        <div class="mileage-bottom-three-time">
          <p>2023</p>
        </div>
        <div class="mileage-bottom-three-text-one">
          <p>
            法国蒙彼利埃分公司成立 <br />
            French Branch at Montpellier, France
          </p>
        </div>
      </div>
    </div>
    <div class="cooperation">
      <div class="container-fluid cooperation-text">
        <p>合作伙伴</p>
        <span class="line"></span>
      </div>
    </div>
    <div class="cooperation-image">
      <img src="../../../assets/images/friend.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      active: 1,
      activeplan: 1,
      timer: "",

      leftnum: "-1000px",
      introtimer: "",
      area: "亚洲地区",
      plantop: "75px",
    };
  },
  created() {
    this.startInterval();
    this.introInterval();
  },
  methods: {
    introInterval() {
      this.introtimer = window.setInterval(() => {
        if (this.active == 1) {
          this.active = this.active + 1;
          this.leftnum = "-800px";
        } else if (this.active == 2) {
          this.active = this.active + 1;
          this.leftnum = "-600px";
        } else if (this.active == 3) {
          this.active = this.active + 1;
          this.leftnum = "-400px";
        } else if (this.active == 4) {
          this.active = this.active + 1;
          this.leftnum = "-200px";
        } else if (this.active == 5) {
          this.active = 1;
          this.leftnum = "-1000px";
        }
      }, 3000);
    },
    hover(index) {
      if (index == 1) {
        this.leftnum = "-1000px";
      } else if (index == 2) {
        this.leftnum = "-800px";
      } else if (index == 3) {
        this.leftnum = "-600px";
      } else if (index == 4) {
        this.leftnum = "-400px";
      } else if (index == 5) {
         this.leftnum = "-200px";
      }
      this.active = index;
      window.clearInterval(this.introtimer);
    },
    clickplan(index) {
      if (index == 1) {
        this.plantop = "75px";
        this.area = "亚洲地区";
      } else if (index == 2) {
        this.plantop = "222px";
        this.area = "欧洲地区";
      } else if (index == 3) {
        this.plantop = "369px";
        this.area = "北美地区";
      }
      this.activeplan = index;
      window.clearInterval(this.timer);
    },
    startInterval() {
      this.timer = window.setInterval(() => {
        if (this.activeplan == 1) {
          this.activeplan = this.activeplan + 1;
          this.plantop = "222px";
          this.area = "欧洲地区";
        } else if (this.activeplan == 2) {
          this.activeplan = this.activeplan + 1;
          this.plantop = "369px";
          this.area = "北美地区";
        } else if (this.activeplan == 3) {
          this.activeplan = 1;
          this.plantop = "75px";
          this.area = "亚洲地区";
        }
      }, 3000);
    },
  },
};
</script>
<style lang="scss" scoped>
.home-banner {
  height: 1050px;
  width: 100%;
  background: url(../../../assets/images/hearder.png);
  background-size: 100% 100%;
  position: relative;
  margin: 0 auto;
}

.consult {
  position: absolute;
  left: 0;
  top: 273px;
  width: 1062px;
  height: 252px;
  background-color: rgba(35, 77, 211, 0.8);
  opacity: 0.5;
  padding: 60px;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding-left: 450px;

  p {
    font-size: 34px;
    color: #ffffff;
    line-height: 48px;
    margin-bottom: 40px !important;
  }

  span {
    width: 164px;
    height: 64px;
    background: #ffffff;
    font-size: 20px;
    color: #234dd3;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
}

.consult-input {
  position: absolute;
  bottom: -116px;
  right: 0;
  width: 501px;
  height: 64px;
  background: #ffffff;
  display: flex;

  input {
    flex: 1;
    height: 100%;
    outline: none;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    border: 0 none;
  }

  span {
    width: 103px;
    height: 64px;
    background: #234dd3;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
  }
}

.banner-bottom {
  position: absolute;
  bottom: 0;
  // height: 197px;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.banner-bottom-list {
  align-items: center;
  color: #fff;
  list-style: none;
  display: flex;
  justify-content: center;

  li {
    width: 200px;
    height: 198px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  img {
    width: 64px;
    height: 64px;
  }

  span {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
  }
}

.anima {
  position: relative;
  background: linear-gradient(180deg, #234dd3 0%, #234dd3 100%);
  z-index: 1;
  transition: all 0.3s;
  left: 0;
  width: 200px;
  height: 198px;
}

.banner-bottom-list li.active .banner-bottom-list-item-content {
  z-index: 2;
}

.banner-bottom-list-item-content {
  height: 178px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 0;
  transform: translateY(178px);
  background: #234dd3;
}

.banner-bottom-list-item-content-inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 26px;
    color: #ffffff;
    line-height: 38px;
    white-space: nowrap;
    margin: 0;
    margin-right: 60px;
  }

  p {
    font-size: 14px;
    color: #ffffff;
    line-height: 30px;
    max-width: 690px;
    margin: 0;
  }
}

.banner-bottom-info {
  height: 178px;
  background: #234dd3;
}

.banner-bottom-list li:hover {
  cursor: pointer;
}

.home-network {
  width: 100%;
  height: 748px;
  background: #fff;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 200px;

  .network-sw {
    width: 80%;
    margin-top: 100px;
    .network-top {
      font-weight: bold;
      width: 156px;
      font-size: 26px;
      color: #333333;
      margin-left: 39px;
      .line {
        margin-top: 25px;
        width: 40px;
        height: 4px;
        background: #234dd3;
      }
    }
  }
}

.plan-type {
  width: 100%;
  height: 590px;
  display: flex;

  .plan-type-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.plan-type-right {
  flex: 2;
  position: relative;
}
.plan-type {
  position: relative;
}
.plan-type-left-card {
  width: 284px;
  height: 147px;

  transform: translateX(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  background: #101d48;
}

.plan-type-active {
  position: absolute;
  font-size: 26px;
  background: #234dd3;
  z-index: 5;
  width: 284px;
  height: 147px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  top: 75px;
  left: 40px;
  transition: all 0.3s;
}

.plan-type-right-card {
  display: none;
}

.plan-type-right-card.active {
  display: block;
}

.plan-type-right img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.plan-type-right-inner {
  width: 807px;
  height: 176px;
  background: #081d60;
  opacity: 0.8;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  padding: 32px 47px 0 58px;
  position: absolute;
  margin-top: 194px;
  left: 0;
  z-index: 4;
}

.plan-type-right-inner p {
  width: 702px;
  height: 107px;
  line-height: 20px;
  margin-top: 29px;
}

.plan-type-right-tabs {
  position: absolute;
  bottom: 40px;
  left: 0;
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
  padding-left: 48px;
}

.plan-type-right-tabs span {
  margin-right: 168px;
}

.plan-consult {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan-consult > p {
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}

.plan-consult > span {
  margin-top: 25px;
  width: 132px;
  height: 56px;
  background: #234dd3;
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.network-sw {
  width: 100%;
}

.network-foot {
  margin-top: 80px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .network-foot-font {
    font-size: 16px;
    color: #333333;
    line-height: 22px;
  }

  .network-foot-btn {
    margin-top: 40px;
    width: 132px;
    height: 56px;
    background: #234dd3;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.territory {
  margin-top: 390px;
  background: url("../../../assets/images/industry-background.jpg");
  background-size: 100% 100%;
  width: 100%;
  height: 718px;
  display: flex;
  flex-direction: column;
  position: relative;

  p {
    margin-left: 42.92%;
    margin-top: 72px;
    width: 104px;
    height: 37px;
    font-size: 26px;
    font-weight: bold;
    color: #ffffff;
    line-height: 37px;
  }

  .line {
    margin-left: 44.79%;
    margin-top: 13px;
    display: flex;
    width: 30px;
    height: 4px;
    background: #234dd3;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.territory-dalou {
  z-index: 1;
  top: -107px;
  left: 63.7%;
  position: absolute;
}

.territory-first {
  position: absolute;
  margin-top: 191px;
  right: 34.9%;
  z-index: 2;
}

.mileage {
  position: relative;
  width: 100%;
  height: 750px;
  background: url("../../../assets/images/licheng.jpg");
  z-index: 3;
}

.mileage-top {
  position: absolute;
  margin-left: 18.8%;
  width: 10.2%;
  height: 250px;
  background: #234dd3;
}

.mileage-top-one {
  position: absolute;
  margin-left: 21.43%;
}

.mileage .line {
  display: inline-block;
  width: 40px;
  height: 4px;
  background: #234dd3;
}

.mileage-top-one p {
  margin-top: 70px;
  width: 104px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  line-height: 37px;
}

.mileage-top-two {
  position: absolute;
  margin-left: 34.7%;
}

.mileage-top-two p {
  margin-top: 141px;
  width: 104px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  line-height: 37px;
}

.mileage-top .line {
  position: absolute;
  margin-top: 124px;
  margin-left: 116px;
  display: flex;
  width: 30px;
  height: 4px;
  background: white;
}

.mileage-top-three {
  margin-left: 29%;
  width: 13.5%;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.8);
}

.mileage-top-three-time {
  margin-top: 40px;
  margin-left: 5.3%;
  position: absolute;
}

.mileage-top-three-time p {
  width: 58px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #234dd3;
  line-height: 37px;
  text-align: center;
}

.mileage-top-three-text {
  margin-top: 121px;
  margin-left: 2%;
  position: absolute;
}

.mileage-top-three-text p {
  width: 163px;
  height: 96px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-top-four {
  position: absolute;
  top: 0;
  margin-left: 42.5%;
  width: 21.2%;
  height: 250px;
  background-color: rgba(35, 77, 211, 0.8);
}

.mileage-top-four-time {
  margin-top: 40px;
  margin-left: 43.1%;
  position: absolute;
}

.mileage-top-four-time p {
  text-align: center;
  width: 58px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  line-height: 37px;
}

.mileage-top-four-text {
  margin-top: 113px;
  margin-left: 18%;
  position: absolute;
}

.mileage-top-four-text p {
  width: 249px;
  height: 72px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-top-five {
  position: absolute;
  margin-left: 63.7%;
  top: 0;
  width: 17.55%;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.8);
}

.mileage-top-five-time {
  margin-top: 40px;
  margin-left: 42.14%;
  position: absolute;
}

.mileage-top-five-time p {
  width: 57px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #234dd3;
  line-height: 37px;
}

.mileage-top-five-text {
  margin-top: 93px;
  margin-left: 6.82%;
  position: absolute;
}

.mileage-top-five-text p {
  width: 296px;
  height: 72px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-top-five-text-two {
  margin-top: 154px;
  margin-left: 26.71%;
  position: absolute;
}

.mileage-top-five-text-two p {
  width: 162px;
  height: 48px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-middle-one {
  margin-left: 18.8%;
  width: 23.75%;
  height: 250px;
  background-color: rgba(35, 77, 211, 0.8);
  position: absolute;
}

.mileage-middle-one-time {
  margin-left: 43.2%;
  margin-top: 40px;
}

.mileage-middle-one-time p {
  width: 58px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  line-height: 37px;
}

.mileage-middle-one-text-one {
  margin-left: 17.32%;
  margin-top: 13px;
  position: absolute;
}

.mileage-middle-one-text-one p {
  width: 293px;
  height: 48px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-middle-one-text-two {
  margin-left: 17.32%;
  margin-top: 73px;
  position: absolute;
}

.mileage-middle-one-text-two p {
  width: 304px;
  height: 96px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-middle-two {
  margin-left: 42.5%;
  width: 21.2%;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
}

.mileage-middle-two-time {
  margin-left: 41.52%;
  margin-top: 40px;
}

.mileage-middle-two-time p {
  width: 58px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #234dd3;
  line-height: 37px;
  text-align: center;
}

.mileage-middle-two-text-one {
  margin-left: 36.61%;
  margin-top: 13px;
  position: absolute;
}

.mileage-middle-two-text-one p {
  width: 98px;
  height: 48px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-middle-two-text-two {
  margin-left: 17.44%;
  margin-top: 77px;
  position: absolute;
}

.mileage-middle-two-text-two p {
  width: 254px;
  height: 48px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-middle-three {
  margin-left: 63.7%;
  width: 17.55%;
  height: 250px;
  background-color: rgba(35, 77, 211, 0.8);
  position: absolute;
}

.mileage-middle-three-time {
  margin-left: 40.95%;
  margin-top: 41px;
}

.mileage-middle-three-time p {
  width: 3.28%;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  line-height: 37px;
  text-align: center;
}

.mileage-middle-three-text-one {
  margin-left: 24.71%;
  margin-top: 13px;
  position: absolute;
}

.mileage-middle-three-text-one p {
  width: 159px;
  height: 72px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-bottom-one {
  margin-top: 250px;
  margin-left: 18.8%;
  width: 23.75%;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
}

.mileage-bottom-one-time {
  margin-left: 43.2%;
  margin-top: 40px;
}

.mileage-bottom-one-time p {
  width: 3.02%;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #234dd3;
  line-height: 37px;
  text-align: center;
}

.mileage-bottom-one-text-one {
  margin-left: 21.5%;
  margin-top: 13px;
  position: absolute;
}

.mileage-bottom-one-text-one p {
  width: 255px;
  height: 48px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-bottom-one-text-two {
  margin-left: 21.5%;
  margin-top: 73px;
  position: absolute;
}

.mileage-bottom-one-text-two p {
  width: 241px;
  height: 48px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-bottom-two {
  margin-top: 250px;
  margin-left: 42.5%;
  width: 21.2%;
  height: 250px;
  background-color: rgba(35, 77, 211, 0.8);
  position: absolute;
}

.mileage-bottom-two-time {
  margin-left: 41.52%;
  margin-top: 40px;
}

.mileage-bottom-two-time p {
  width: 63px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  line-height: 37px;
  text-align: center;
}

.mileage-bottom-two-text-one {
  margin-left: 20%;
  margin-top: 13px;
  position: absolute;
}

.mileage-bottom-two-text-one p {
  width: 215px;
  height: 48px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.mileage-bottom-three {
  margin-top: 250px;
  margin-left: 63.7%;
  width: 17.55%;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
}

.mileage-bottom-three-time {
  margin-left: 40.95%;
  margin-top: 41px;
}

.mileage-bottom-three-time p {
  width: 63px;
  height: 37px;
  font-size: 26px;
  font-weight: bold;
  color: #234dd3;
  line-height: 37px;
  text-align: center;
}

.mileage-bottom-three-text-one {
  margin-left: 13.35%;
  margin-top: 13px;
  position: absolute;
}

.mileage-bottom-three-text-one p {
  width: 247px;
  height: 72px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
}

.cooperation {
  background: #f4f4f4;
  height: 242px;
  width: 100%;
  position: relative;
}

.cooperation-text {
  position: absolute;
  margin-top: 76px;
  font-weight: bold;
  font-size: 26px;
  height: 104px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cooperation-text .p {
  width: 104px;
  height: 37px;
  font-weight: 800;
  color: #000000;
  line-height: 37px;
}

.cooperation-text .line {
  margin-top: 20px;

  display: flex;
  width: 40px;
  height: 4px;
  background: #234dd3;
}

.cooperation-image {
  height: 516px;
  width: 100%;
  position: relative;

  img {
    height: 516px;
    width: 100%;
    position: absolute;
  }
}
.consult-seek {
  width: 164px;
  height: 64px;
  background: #ffffff;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #234dd3;
  line-height: 28px;
  line-height: 64px;
  font-weight: bold;
  text-align: center;
}
.plan-container {
  margin-top: 40px;
}
</style>
