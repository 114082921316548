var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{
      scroll: !_vm.scrollstatic,
      'head-content': true,
    }},[_vm._m(0),_c('div',{staticClass:"head-link"},[_c('div',{class:{
          hove: _vm.active == 1,
          'head-link-content': true,
        },on:{"click":function($event){return _vm.hover(1)}}},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"head-link-itme",staticStyle:{"width":"35px","line-height":"37px"}},[_vm._v(" 首页 ")])])],1),_c('div',{staticClass:"head-link-line"}),_c('div',{class:{
          hove: _vm.active == 2,
          'head-link-content': true,
        },on:{"click":function($event){return _vm.hover(2)}}},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"head-link-itme"},[_vm._v("产品服务")])])],1),_c('div',{staticClass:"head-link-line"}),_c('div',{class:{
          hove: _vm.active == 3,
          'head-link-content': true,
        },on:{"click":function($event){return _vm.hover(3)}}},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"head-link-itme"},[_vm._v("解决方案")])])],1),_c('div',{staticClass:"head-link-line"}),_c('div',{class:{
          hove: _vm.active == 4,
          'head-link-content': true,
        },on:{"click":function($event){return _vm.hover(4)}}},[_c('router-link',{attrs:{"to":"/about"}},[_c('div',{staticClass:"head-link-itme"},[_vm._v(" 关于起东 ")])])],1)]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head-content-left"},[_c('img',{staticClass:"head-content-logo",attrs:{"src":require("../../assets/images/logo.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head-iphone"},[_c('img',{staticClass:"head-iphone-img",attrs:{"src":require("../../assets/images/phone3.png")}}),_c('div',{staticClass:"head-iphone-text"},[_vm._v(" 400-097-0790 ")])])}]

export { render, staticRenderFns }