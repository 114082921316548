import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import confirm from "@cmp/confirm";
import notify from "@cmp/notify";
import "lib-flexible";
import FastClick from "fastclick";
import "animate.css";

// import test from '@cmp/test'
import request from "@/utils/request";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
import "@assets/css/bootstrap.4.6.2.min.css";
import "@assets/css/theme.scss";

Vue.config.productionTip = false;

Vue.prototype.$confirm = confirm;
Vue.prototype.$notify = notify;

// Vue.use(test)  // 方法1.仅在组件里使用
// Vue.prototype.$test = test  // 方法2.组件和组件以外的情况下使用

AOS.init();

Vue.prototype.$http = request;
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
FastClick.attach(document.body);
