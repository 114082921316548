<template>
  <div class="home">
    <div v-if="!$vuetify.breakpoint.mobile">
      <HomePc></HomePc>
    </div>
    <div v-if="$vuetify.breakpoint.mobile">
      <HomeMobile></HomeMobile>
    </div>
  </div>
</template>

<script>
import HomeMobile from "@views/Mobile/HomeMobile/HomeMobile";
import HomePc from "@views/Pc/HomePc/HomePc";

export default {
  components: {
    HomeMobile,
    HomePc,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
<style lang="scss" scoped></style>
