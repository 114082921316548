<template>
  <v-app class="g-app" id="app">
    <div v-if="!$vuetify.breakpoint.mobile">
      <Head></Head>
      <router-view></router-view>
      <ul class="fixed-right-info">
        <li class="">
          <img src="./assets/images/customer-service.png" alt="" />
        </li>
        <li><img src="./assets/images/email1.png" alt="" /></li>
        <li><img src="./assets/images/phone1.png" alt="" /></li>
      </ul>
      <Foot></Foot>
    </div>
    <div v-if="$vuetify.breakpoint.mobile">
      <Head></Head>
      <router-view></router-view>
      <Foot></Foot>
    </div>
  </v-app>
</template>

<script>
import Foot from "@views/Foot";
import Head from "@views/Head";
export default {
  name: "App",
  components: {
    Head,
    Foot,
  },
  created() {
    console.log(this.$vuetify.breakpoint);
  },

  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss">
.fixed-right-info {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 27px;
  list-style: none;
  z-index: 10;

  li {
    width: 44px;
    height: 44px;
    background: rgba(0, 0, 0, 0.6);
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fixed-right-info li:hover,
.fixed-right-info li.active {
  background: rgba(35, 77, 211);
}
</style>
