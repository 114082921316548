<template>
  <div>
    <div
      :class="{
        scroll: !scrollstatic,
        'head-content': true,
      }"
    >
      <div class="head-content-left">
        <img class="head-content-logo" src="../../assets/images/logo.png" />
      </div>
      <div class="head-link">
        <div
          @click="hover(1)"
          :class="{
            hove: active == 1,
            'head-link-content': true,
          }"
        >
          <router-link to="/"
            ><div style="width: 35px;line-height: 37px;" class="head-link-itme">
              首页
            </div></router-link
          >
        </div>
        <div class="head-link-line"></div>

        <div
          @click="hover(2)"
          :class="{
            hove: active == 2,
            'head-link-content': true,
          }"
        >
          <router-link to="/">
            <div class="head-link-itme">产品服务</div></router-link
          >
        </div>
        <div class="head-link-line"></div>

        <div
          @click="hover(3)"
          :class="{
            hove: active == 3,
            'head-link-content': true,
          }"
        >
          <router-link to="/">
            <div class="head-link-itme">解决方案</div></router-link
          >
        </div>
        <div class="head-link-line"></div>

        <div
          @click="hover(4)"
          :class="{
            hove: active == 4,
            'head-link-content': true,
          }"
        >
          <router-link to="/about"
            ><div class="head-link-itme">
              关于起东
            </div>
          </router-link>
        </div>
      </div>
      <div class="head-iphone">
        <img src="../../assets/images/phone3.png" class="head-iphone-img" />
        <div class="head-iphone-text">
          400-097-0790
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      active: 1,
      scrollstatic: true,
    };
  },
  created() {
    window.addEventListener("scroll", this.windowScrollListener);
  },
  mounted() {},
  methods: {
    hover(index) {
      this.active = index;
    },
    windowScrollListener() {
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 900) {
        this.scrollstatic = false;
      }
      if (scrollTop < 900) {
        this.scrollstatic = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.scroll {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  background: #ffffff !important;
}
.hove {
  border-bottom: 3px solid #234dd3;
  padding-bottom: 34px;
  margin-top: 38px;
  text-align: center;
  .head-link-itme {
    text-align: center;

    color: #234dd3 !important;
  }
}
.head-content {
  // justify-content: space-evenly;
  align-items: center;
  position: absolute;
  z-index: 999;
  width: 100%;
  display: flex;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 110px;

  .head-content-left {
    margin-top: 8px;
    margin-left: 400px;
  }
  .head-content-logo {
    width: 87px;
    height: 33px;
  }
  .head-link {
    display: flex;
    height: 99px;
    align-items: center;
    margin-left: 150px;
    margin-right: 150px;

    .head-link-line {
      margin-right: 50px;
      margin-left: 50px;
      width: 1px;
      height: 25px;
      background: rgba(0, 0, 0, 0.2);
      margin-top: 5px;
    }
    .head-link-itme {
      width: 70px;
      height: 35px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      line-height: 22px;
      color: #000000;
      line-height: 35px;
      text-align: center;
      &:hover {
        color: #234dd3;
      }
    }
  }
  .head-iphone {
    display: flex;
    margin-top: 6px;
    .head-iphone-img {
      width: 15px;
      height: 15px;
      margin-top: 3px;
      margin-right: 8px;
    }
    .head-iphone-text {
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
    }
  }
}
</style>
