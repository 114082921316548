<template>
  <div>
    <div v-if="!$vuetify.breakpoint.mobile" class="footer">
      <FootPc></FootPc>
    </div>
    <div v-if="$vuetify.breakpoint.mobile">
      <FootMobile></FootMobile>
    </div>
  </div>
</template>

<script>
import FootPc from "@views/Pc/FootPc";
import FootMobile from "@views/Mobile/FootMobile";
export default {
  components: {
    FootPc,

    FootMobile,
  },
};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 620px;
  background: #222222;
  position: relative;
}

.footer-left {
  width: 5.7%;
  height: 109px;
  margin-top: 84px;
  margin-left: 21.1%;
  position: absolute;
}

.footer-middle {
  position: absolute;
}

.footer-middle-phone {
  margin-left: 578px;
  margin-top: 112px;
  display: flex;
  align-items: center;
}

.footer-middle-phone p {
  opacity: 0.8;
  margin-left: 15px;
  margin-top: 2px;
  width: 101px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  position: relative;
  top: 6px;
}

.footer-middle-address {
  margin-left: 578px;
  display: flex;
  align-items: center;
}

.footer-middle-address p {
  opacity: 0.8;
  margin-left: 15px;
  margin-top: 2px;
  width: 397px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  position: relative;
  top: 17px;
}

.footer-middle-email {
  margin-left: 578px;
  display: flex;
  align-items: center;
}

.footer-middle-email p {
  opacity: 0.8;
  margin-left: 15px;
  width: 154px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  position: relative;
  top: 6px;
}

.footer-middle-photo {
  margin-left: 596px;
  display: flex;
  align-items: center;
}

.footer-middle-photo img {
  margin-top: 40px;
}

.footer-middle-photo p {
  width: 101px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  top: 6px;
}

.footer-text {
  position: absolute;
  margin-left: 580px;
  margin-top: 31px;
}

.footer-text span {
  opacity: 0.8;
  width: 168px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}

.footer-right-one {
  position: absolute;
  margin-left: 66.67%;
  margin-top: 122px;
}

.footer-right-one p {
  width: 72px;
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
}

.footer-right-two {
  position: absolute;
  margin-left: 67.14%;
  margin-top: 222px;
}

.footer-right-two a {
  opacity: 0.8;
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff !important;
  line-height: 20px;
}

.footer-right-three {
  position: absolute;
  margin-left: 67.14%;
  margin-top: 282px;
}

.footer-right-three p {
  opacity: 0.8;
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}

.extend {
  width: 100%;
  height: 100px;
  background: #234dd3;
  display: flex;
  align-items: center;
}

.extend-text {
  margin-left: 38%;
  width: auto;
  height: 25px;
}

.extend-text p {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 25px;
  margin-right: 47px;
}

.extend-button {
  width: 110px;
  height: 44px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #234dd3;
}

.extend-button span {
  width: 110px;
  height: 44px;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  color: #234dd3;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
