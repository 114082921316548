<template>
  <div>
    <div class="foot-seek">
      <div class="foot-seek-title">
        了解更多跨境物流解决方案
      </div>
      <div class="foot-seek-btn">
        立即咨询
      </div>
    </div>
    <div class="foot-contact">
      <div class="contact-img">
        <img src="../../assets/img/logow.png" />
      </div>
      <div class="contact-phone">
        <img src="../../assets/img/dioanhua.png" />
        <div>400-097-0790</div>
      </div>
      <div class="contact-locate">
        <img src="../../assets/img/dizhi.png" />
        <div>info@keystonescm.com</div>
      </div>
      <div class="contact-email">
        <img src="../../assets/img/youxiang.png" />
        <div>
          <div>深圳市龙岗区坂田街道象角塘社区中浩一路2号</div>
          <div>科尔达大厦907室</div>
        </div>
      </div>
      <div class="contact-code">
        <img src="../../assets/img/qrcode_for_gh_0dd7cce42309_344.png" />
        <div>关注公众号，了解最新资讯</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.foot-seek {
  width: 24rem;
  height: 3.84rem;
  display: flex;
  background: #234dd3;
  align-items: center;
  justify-content: center;

  .foot-seek-title {
    font-size: 0.768rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 1.088rem;
    margin-right: 0.64rem;
  }
  .foot-seek-btn {
    width: 5.376rem;
    height: 2.048rem;
    background: #ffffff;
    display: flex;
    font-size: 0.704rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #234dd3;
    align-items: center;
    justify-content: center;
  }
}
.foot-contact {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  width: 24rem;
  height: 24.896rem;
  background-color: #222;
  .contact-img {
    margin-left: 1.408rem;
    img {
      width: 5.632rem;
      height: 5.632rem;
    }
  }
  .contact-phone {
    display: flex;
    margin-left: 1.408rem;
    margin-bottom: 1.152rem;
    align-items: center;
    img {
      margin-right: 0.96rem;
      width: 1.536rem;
      height: 1.536rem;
    }
    div {
      font-size: 0.768rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.088rem;
    }
  }
  .contact-locate {
    display: flex;
    margin-left: 1.408rem;
    margin-bottom: 1.152rem;
    align-items: center;
    img {
      width: 1.536rem;
      height: 1.536rem;
      margin-right: 0.96rem;
    }
    div {
      font-size: 0.768rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.088rem;
    }
  }
  .contact-email {
    display: flex;
    margin-left: 1.408rem;
    margin-bottom: 1.152rem;
    align-items: center;
    img {
      width: 1.536rem;
      margin-right: 0.96rem;
      height: 1.536rem;
    }
    div {
      font-size: 0.768rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 1.088rem;
    }
  }
  .contact-code {
    width: 24rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 5.632rem;
      height: 5.632rem;
      margin-top: 0.64rem;
      margin-bottom: 1.3rem;
    }
    div {
      font-size: 0.768rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
