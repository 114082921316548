<template>
  <div>
    <div v-if="!$vuetify.breakpoint.mobile">
      <HeadPc> </HeadPc>
    </div>
    <div v-if="$vuetify.breakpoint.mobile">
      <HeadMobile></HeadMobile>
    </div>
  </div>
</template>

<script>
import HeadMobile from "@views/Mobile/HeadMobile";
import HeadPc from "@views/Pc/HeadPc";

export default {
  components: {
    HeadMobile,
    HeadPc,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
 
</style>
