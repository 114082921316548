<template>
  <div>
    <div class="home-ban">
      <div class="home-contnent" data-aos="fade-right">
        <div class="home-contnent-title">全链路一站式智能跨境物流服务平台</div>
        <div class="home-contnent-btn">立即咨询</div>
      </div>
      <div class="home-query">
        <div class="home-query-input">
          <input class="text-input" placeholder="输入货物追踪单号" />
        </div>
        <div class="home-query-btn">查询</div>
      </div>
    </div>
    <div data-aos="fade-up">
      <div class="home-navigation">
        <div
          @click="hover(1)"
          :class="{
            active: activeplan == 1,
            'home-navigation-item': true,
          }"
        >
          <img src="../../../assets/img/haiguan.png" />
          <div class="navigation-item-title">FBX</div>
          <div class="navigation-item-title">全程物流</div>
        </div>
        <div
          @click="hover(2)"
          :class="{
            active: activeplan == 2,
            'home-navigation-item': true,
          }"
        >
          <img src="../../../assets/img/qingguan.png" />
          <div class="navigation-item-title">起东</div>
          <div class="navigation-item-title">全球关务</div>
        </div>
        <div
          @click="hover(3)"
          :class="{
            active: activeplan == 3,
            'home-navigation-item': true,
          }"
        >
          <img src="../../../assets/img/cang.png" />
          <div class="navigation-item-title">起东</div>
          <div class="navigation-item-title">海外仓</div>
        </div>
        <div
          @click="hover(4)"
          :class="{
            active: activeplan == 4,
            'home-navigation-item': true,
          }"
        >
          <img src="../../../assets/img/quanqiu.png" />
          <div class="navigation-item-title">起东</div>
          <div class="navigation-item-title">全球货代</div>
        </div>
        <div
          @click="hover(5)"
          :class="{
            active: activeplan == 5,
            'home-navigation-item': true,
          }"
        >
          <img src="../../../assets/img/wuliu.png" />
          <div class="navigation-item-title">起东商业</div>
          <div class="navigation-item-title">快件KeyEx</div>
        </div>
      </div>
      <div class="home-navigation-text">
        <div class="text-content" v-if="activeplan == 1">
          起东国际供应链致力于成为全球领先的物流服务提供商。多年来，我们与众多客户合作，建立了强大的网络和丰富的物流经验。我们的专业团队和先进的技术保证了我们在物流行业的领先地位。
        </div>
        <div class="text-content" v-if="activeplan == 2">
          起东全球关务服务是起东国际供应链的专业关务服务部门。我们汇聚了一支经验丰富、专业可靠的关务团队，致力于为客户提供全面的跨境贸易支持和定制化的关务服务。
        </div>
        <div class="text-content" v-if="activeplan == 3">
          起东海外仓是起东国际供应链的海外仓储服务产品。我们为客户提供高效、安全、灵活的海外仓储解决方案，帮助您更好地管理库存、降低物流成本，并加速您的全球业务发展。
        </div>
        <div class="text-content" v-if="activeplan == 4">
          起东全球货代服务是起东国际供应链的专业货运代理服务部门。我们拥有丰富的国际货运经验和全球合作伙伴网络，致力于为客户提供一流的货代服务，无论是海运、空运还是陆运，我们都能为您提供全程支持。
        </div>
        <div class="text-content" v-if="activeplan == 5">
          起东商业快件服务是起东国际供应链的专业快递服务部门。我们拥有广泛的网络和先进的运输技术，致力于为客户提供高效、安全的商业快递服务，满足您的快递运输需求。
        </div>
      </div>
    </div>
    <div class="home-richness">
      <div class="home-richness-title">
        <div>丰富的</div>
        <div style="margin-top: .48rem;margin-bottom: .48rem;">
          起东全球网络
        </div>
        <div class="line"></div>
      </div>
      <div class="home-richness-swiper">
        <div class="crichness-swiper-left" data-aos="fade-right">
          <div
            @click="clickswiper(1)"
            :class="{
              swiperactive: swiperstatic == 1,
            }"
          >
            亚洲地区
          </div>
          <div
            @click="clickswiper(2)"
            :class="{
              swiperactive: swiperstatic == 2,
            }"
          >
            欧洲地区
          </div>
          <div
            @click="clickswiper(3)"
            :class="{
              swiperactive: swiperstatic == 3,
            }"
          >
            北美地区
          </div>
        </div>
        <div class="crichness-swiper-right" data-aos="fade-left">
          <div
            :class="{
              swiperImgOne: true,
              zindex: swiperstatic == 1,
            }"
          >
            <div class="crichness-swiper-content" v-if="swiperstatic == 1">
              起东国际供应链建立了一个庞大而完善的全球物流网络，覆盖了多个国家和地区的重要港口和物流枢纽。以下是对起东国际供应链全球网络的简要介绍：
              亚洲地区：作为总部所在地的中国，起东国际供应链在中国内地拥有深圳、青岛、北京、上海、成都的物流网络覆盖。同时，我们在亚洲其他国家和地区，如日本、韩国等也设有物流合作伙伴，以满足不同地区客户的需求。
            </div>
          </div>
          <div
            :class="{
              swiperImgTwo: true,
              zindex: swiperstatic == 2,
            }"
          >
            <div class="crichness-swiper-content" v-if="swiperstatic == 2">
              起东国际供应链在欧洲各主要国家建立了广泛的物流网络。我们拥有物流中心和分支机构，涵盖了比利时、德国、法国、意大利、波兰等欧洲主要经济体。通过这些网络，我们能够为客户提供全面的物流服务，满足他们在欧洲市场的需求。
            </div>
          </div>
          <div
            :class="{
              swiperImgThere: true,
              zindex: swiperstatic == 3,
            }"
          >
            <div class="crichness-swiper-content" v-if="swiperstatic == 3">
              起东国际供应链在北美地区建立了强大的物流网络。我们在美国东部和西部设有办事处和仓储设施，以支持客户在北美的物流需求。通过合作伙伴关系，我们还能够覆盖到整个北美市场。
            </div>
          </div>
          <div class="crichness-swiper-title">
            <div class="crichness-swiper-item">
              <div>
                1对1专业出口
              </div>
              <div>
                顾问服务
              </div>
            </div>
            <div class="crichness-swiper-item">
              <div>
                缩短50%以上
              </div>
              <div>
                准备时间
              </div>
            </div>
            <div class="crichness-swiper-item">
              <div>
                全流程指导
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-richness-bottom">
        <div class="home-richness-text">了解更多行业定制方案？</div>
        <div class="home-richness-btn">立即咨询</div>
      </div>
    </div>
    <div class="home-lead">
      <div class="home-lead-left">
        <div class="lead-left-title">
          <div>引领行业</div>
          <div class="title-line"></div>
        </div>
        <div class="lead-left-sign" data-aos="fade-right">
          <div class="lead-left-sign-top">
            <div class="left-sign-top-item">
              <div class="sign-top-item-content">
                <div class="content-texts">专业关务</div>
                <img src="../../../assets/img/yinglinghangye-icon.png" />
              </div>
              <div class="sign-top-item-describe">
                10年+
              </div>
            </div>
            <div class="left-sign-top-item">
              <div class="sign-top-item-content">
                <div class="content-texts">自营海外仓</div>

                <img src="../../../assets/img/yinglinghangye-icon.png" />
              </div>
              <div class="sign-top-item-describe">
                6个
              </div>
            </div>
            <div class="left-sign-top-item">
              <div class="sign-top-item-content">
                <div class="content-texts">船司</div>
                <img src="../../../assets/img/yinglinghangye-icon.png" />
              </div>
              <div class="sign-top-item-describe">
                10+
              </div>
            </div>
          </div>
          <div class="lead-left-sign-bottom">
            <div class="left-sign-bottom-item">
              <div class="sign-bottom-item-content">
                <div class="content-texts">海外</div>
                <img src="../../../assets/img/yinglinghangye-icon.png" />
              </div>
              <div class="sign-bottom-item-describe">
                自营车队
              </div>
            </div>
            <div class="left-sign-bottom-item">
              <div class="sign-bottom-item-content">
                <div class="content-texts">海陆空</div>

                <img src="../../../assets/img/yinglinghangye-icon.png" />
              </div>
              <div class="sign-bottom-item-describe">
                多样化运输
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-lead-right" data-aos="fade-left">
        <img src="../../../assets/img/dalou.png" />
      </div>
    </div>
    <div class="home-mileage">
      <div class="home-mileage-left">
        <div class="home-mileage-content">
          <div class="home-mileage-content-title">
            一路走来的里程
          </div>
          <div class="home-mileage-content-line"></div>
        </div>

        <div class="home-mileage-sixteen">
          <div class="home-mileage-sixteen-title">
            2016
          </div>
          <div class="home-mileage-sixteen-content">
            获得比利时自营关务
          </div>
          <div class="home-mileage-sixteen-content">
            及海关保税仓牌照
          </div>
          <div class="home-mileage-sixteen-content">
            Customs Clearance Agent
          </div>
          <div class="home-mileage-sixteen-content">
            & Bonded Warehouse License
          </div>
          <div class="home-mileage-sixteen-content">
            in Belgium
          </div>
        </div>
        <div class="home-mileage-eighteen">
          <div class="home-mileage-eighteen-title">
            2018
          </div>
          <div class="home-mileage-eighteen-content">
            获得比利时年度最佳物流项目奖
          </div>
          <div class="home-mileage-eighteen-content">
            Awarded as Project
          </div>
          <div class="home-mileage-eighteen-content">
            of the year of Belgium
          </div>
          <div class="home-mileage-eighteen-content">
            航空货代分公司在比利时列日成立
          </div>
          <div class="home-mileage-eighteen-content">
            Airfreight Forwarder
          </div>
          <div class="home-mileage-eighteen-content">
            Branch at LGG, Belgium
          </div>
        </div>
        <div class="home-mileage-twenty">
          <div class="home-mileage-twenty-title">
            2020
          </div>
          <div
            style="display: flex;
    flex-direction: column;
    align-items: center;"
          >
            <div class="home-mileage-twenty-content">
              中国青岛分公司成立
            </div>
            <div class="home-mileage-twenty-content">
              Qingdao Branch, China
            </div>
          </div>
        </div>

        <div class="home-mileage-twentyTwo">
          <div class="home-mileage-twentyTwo-title">
            2022
          </div>
          <div
            style="display: flex;
    flex-direction: column;
    align-items: center;"
          >
            <div class="home-mileage-twentyTwo-content">
              新加坡分公司成立
            </div>
            <div class="home-mileage-twentyTwo-content">
              Singaporean Branch, Singapore
            </div>
          </div>
        </div>
        <div class="home-mileage-end"></div>
      </div>
      <div class="home-mileage-right">
        <div class="home-mileage-fifteen">
          <div class="home-mileage-fifteen-title">
            2015
          </div>
          <div class="home-mileage-fifteen-content">
            创立于比利时维塞
          </div>
          <div class="home-mileage-fifteen-content">
            Start from Vise,
          </div>
          <div class="home-mileage-fifteen-content">
            Belgium
          </div>
        </div>
        <div class="home-mileage-seventeen">
          <div class="home-mileage-seventeen-title">
            2017
          </div>
          <div class="home-mileage-seventeen-content">
            总部迁往比利时列日机场
          </div>
          <div class="home-mileage-seventeen-content">
            Headquarter Moved to
          </div>
          <div class="home-mileage-seventeen-content">
            LGG Airport, Belgium
          </div>
          <div class="home-mileage-seventeen-content">
            中国深圳分公司成立
          </div>
          <div class="home-mileage-seventeen-content">
            Shenzhen Branch, China
          </div>
        </div>
        <div class="home-mileage-nineteen">
          <div class="home-mileage-nineteen-title">
            2019
          </div>
          <div class="home-mileage-nineteen-content">
            第一辆自营卡车
          </div>
          <div class="home-mileage-nineteen-content">
            Fisrt Truck
          </div>
          <div class="home-mileage-nineteen-content">
            德国法兰克福分公司成立
          </div>
          <div class="home-mileage-nineteen-content">
            German Branch
          </div>
          <div class="home-mileage-nineteen-content">
            at Frankfurt, Germany
          </div>
        </div>
        <div class="home-mileage-twentyOne">
          <div class="home-mileage-twentyOne-title">
            2021
          </div>
          <div class="home-mileage-twentyOne-content">
            美国洛杉矶分公司成立
          </div>
          <div class="home-mileage-twentyOne-content">
            American Branch
          </div>
          <div class="home-mileage-twentyOne-content">
            at Los Angeles, USA
          </div>
          <div class="home-mileage-twentyOne-content">
            自营卡车公司在比利时列日成立
          </div>
          <div class="home-mileage-twentyOne-content">
            Transport Branch at Liege, Belgium
          </div>
        </div>
        <div class="home-mileage-twentyThree">
          <div class="home-mileage-twentyThree-title">
            2023
          </div>
          <div class="home-mileage-twentyThree-content">
            法国蒙彼利埃分公司成立
          </div>
          <div class="home-mileage-twentyThree-content">
            French Branch
          </div>
          <div class="home-mileage-twentyThree-content">
            at Montpellier, France
          </div>
        </div>
      </div>
    </div>
    <div class="home-cooperate">
      <div class="home-cooperate-title">
        合作伙伴
      </div>
      <div class="home-cooperate-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      active: 1,
      activeplan: 1,
      swiperstatic: 1,
      timer: "",
    };
  },
  created() {
    this.startInterval();
  },
  methods: {
    hover(index) {
      this.activeplan = index;
    },
    clickswiper(index) {
      this.swiperstatic = index;
      window.clearInterval(this.timer);
    },
    startInterval() {
      this.timer = window.setInterval(() => {
        this.swiperstatic = this.swiperstatic + 1;
        if (this.swiperstatic == 4) {
          this.swiperstatic = 1;
        }
      }, 2000);
    },
  },
};
</script>
<style lang="scss" scoped>
.text-input:focus {
  outline: none;
}
.home {
  width: 24rem;
}
.home-ban {
  width: 24rem;
  height: 19.2rem;
  background-image: url(../../../assets/img/souping.jpg);
  background-size: cover;
  position: relative;
  .home-contnent {
    width: 17.28rem;
    height: 5.76rem;
    background: rgb(34, 77, 210, 0.8);
    position: absolute;
    top: 7.552rem;
    left: 0px;
    .home-contnent-title {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      font-size: 0.832rem;
      margin-top: 0.896rem;
      margin-left: 2.88rem;
    }
    .home-contnent-btn {
      position: relative;
      width: 4.64rem;
      height: 1.824rem;
      background: #ffffff;
      color: #234dd3;
      font-size: 0.704rem;
      text-align: center;
      line-height: 1.824rem;
      margin-top: 0.896rem;
      margin-left: 2.88rem;
      font-weight: bold;
    }
  }

  .home-query {
    position: absolute;
    display: flex;
    left: 3rem;
    width: 19rem;
    height: 2.3rem;
    bottom: 2rem;
    .home-query-input {
      width: 13rem;
      height: 2.3rem;

      background: #fff;
      padding-left: 0.768rem;
      padding-top: 0.6rem;
    }
    .home-query-btn {
      width: 6em;
      height: 2.3rem;
      background-color: #234dd3;
      font-weight: bold;
      color: #ffffff;
      font-size: 0.704rem;
      line-height: 2.3rem;
      text-align: center;
    }
  }
}

.home-navigation {
  width: 24rem;
  height: 5.76rem;
  background-color: #112f8d;
  display: flex;
  .home-navigation-item {
    width: 4.8rem;
    height: 5.76rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    img {
      width: 2.176rem;
      height: 2.176rem;
    }
  }
}
.home-navigation-text {
  background-color: #234dd3;
  width: 24rem;
  padding-left: 1.536rem;
  padding-top: 1.536rem;
  padding-right: 1.536rem;
  padding-bottom: 1.536rem;

  .text-content {
    font-size: 0.7rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 1.9rem;
  }
}
.active {
  background: #234dd3;
}
.home-richness {
  width: 24rem;
  height: 34rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .home-richness-title {
    margin-top: 1.92rem;
    margin-bottom: 1.92rem;
    display: flex;
    font-size: 0.896rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 1.28rem;
    flex-direction: column;
  }
  .home-richness-swiper {
    width: 24rem;
    height: 17.28rem;
    display: flex;
    .crichness-swiper-left {
      display: flex;
      z-index: 999;
      background-color: #101d48;
      flex-direction: column;
      justify-content: center;
      width: 5rem;
      height: 10.752rem;
      margin-top: 0.96rem;

      div {
        display: flex;
        color: #fff;
        font-size: 0.768rem;
        width: 5.5rem;
        height: 3.584rem;
        align-items: center;
        justify-content: center;
        z-index: 999;
      }
    }
    .crichness-swiper-right {
      width: 19.02rem;
      height: 17.376rem;
      position: relative;

      .crichness-swiper-img {
      }
      .swiperImgOne {
        width: 19.02rem;
        height: 13.92rem;
        background-size: cover;
        display: flex;
        align-items: center;
        background-image: url(../../../assets/img/yazhou.jpg);
        position: absolute;
        left: -0.02rem;
        // background-color: beige;

        z-index: 3;
      }
      .zindex {
        z-index: 3 !important;
      }

      .swiperImgTwo {
        width: 19.02rem;
        height: 13.92rem;
        background-size: cover;
        display: flex;
        align-items: center;
        // background-color: beige;
        left: -0.02rem;

        background-image: url(../../../assets/img/ouzhou.jpg);
        position: absolute;
        z-index: 2;
      }
      .swiperImgThere {
        width: 19.02rem;
        height: 13.92rem;
        background-size: cover;
        display: flex;
        align-items: center;
        // background-color: beige;
        background-image: url(../../../assets/img/beimei.jpg);
        position: absolute;
        left: -0.02rem;

        z-index: 1;
      }

      .crichness-swiper-title {
        width: 19.02rem;
        height: 3.456rem;
        position: absolute;
        bottom: 0;
        background: #101d48;
        display: flex;
        font-size: 0.64rem;
        color: #ffffff;
        align-items: center;
        justify-content: space-evenly;
        left: -0.02rem;

        .crichness-swiper-item {
          text-align: right;
        }
      }
      .crichness-swiper-content {
        width: 17rem;
        height: 10.912rem;
        background-color: #101d48;
        opacity: 0.7;
        font-size: 0.64rem;
        color: #ffffff;
        line-height: 1rem;
        padding-left: 1.664rem;
        padding-top: 1.024rem;
        padding-right: 1.088rem;
        display: flex;
        align-items: center;
      }
    }
  }
  .home-richness-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-richness-text {
    font-size: 0.768rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 1.088rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .home-richness-btn {
    width: 6.336rem;
    height: 2.56rem;
    background: #234dd3;
    display: flex;
    font-size: 0.704rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 1.024rem;
    align-items: center;
    justify-content: center;
  }
  .line {
    width: 1.28rem;
    height: 0.128rem;
    background-color: #234dd3;
  }
}
.swiperactive {
  font-size: 0.896rem !important;
  width: 6rem !important;
  background: #234dd3;
}

.home-lead {
  width: 24rem;
  height: 14.656rem;
  background-image: url(../../../assets/img/yinlian.jpg);
  position: relative;
  background-size: cover;
  .home-lead-left {
    width: 18.912rem;
    height: 14.656rem;
    .lead-left-title {
      padding-top: 1.92rem;
      margin-left: 10.24rem;
      font-size: 0.896rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 1.28rem;
    }
    .title-line {
      width: 1.28rem;
      height: 0.128rem;
      background: #234dd3;
      margin-top: 0.4rem;
      margin-left: 1.1rem;
      margin-bottom: 0.8rem;
    }
    .lead-left-sign {
      // display: flex;
      // flex-direction: column;
      width: 18.912rem;
      position: absolute;
      z-index: 999;
      .lead-left-sign-top {
        display: flex;
        justify-content: space-evenly;
        .left-sign-top-item {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .sign-top-item-describe {
          font-size: 1.28rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          line-height: 1.792rem;
        }
        .sign-top-item-content {
          font-size: 0.704rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 1.024rem;
          position: relative;
          img {
            width: 0.768rem;
            height: 0.768rem;
            position: absolute;
            right: -0.5rem;
            top: -0.16rem;
          }
        }
      }
      .lead-left-sign-bottom {
        display: flex;
        justify-content: space-evenly;
        margin-top: 2rem;
        .left-sign-bottom-item {
          // display: flex;
          // flex-direction: column;
          // align-items: center;
        }
        .sign-bottom-item-describe {
          font-size: 0.768rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          line-height: 1.088rem;
        }
        .sign-bottom-item-content {
          font-size: 0.768rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          line-height: 1.088rem;
          position: relative;
          img {
            width: 0.768rem;
            height: 0.768rem;
            position: absolute;
            right: 1.15rem;
            opacity: 0.8;
            top: -0.16rem;
          }
        }
      }
    }
  }
  .home-lead-right {
    position: absolute;
    right: 0;
    bottom: 0;
    img {
      width: 8.896rem;
      height: 15.68rem;
    }
  }
}
.home-mileage {
  width: 24rem;
  height: 42.24rem;
  background-image: url(../../../assets/img/yinglinghangye-beijing.jpg);
  background-size: cover;
  display: flex;
  margin-top: -0.1rem;
  .home-mileage-left {
    display: flex;
    flex-direction: column;
    .home-mileage-content {
      width: 12.032rem;
      height: 2.88rem;
      background: linear-gradient(
        90deg,
        rgb(0, 0, 0, 0.5) 0%,
        rgb(8, 31, 99, 0.5) 100%
      );
      display: flex;
      align-items: center;
      justify-content: center;

      .home-mileage-content-title {
        font-size: 0.896rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.28rem;
        margin-right: 0.32rem;
      }
      .home-mileage-content-line {
        width: 1.28rem;
        height: 0.128rem;
        background: #234dd3;
      }
    }
    .home-mileage-sixteen {
      width: 12.032rem;
      height: 8.448rem;
      background: rgb(0, 0, 0, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      .home-mileage-sixteen-title {
        font-size: 0.768rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #234dd3;
        line-height: 1.088rem;
        margin-top: 1.088rem;
        margin-bottom: 0.576rem;
      }
      .home-mileage-sixteen-content {
        font-size: 0.64rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.896rem;
      }
    }
    .home-mileage-eighteen {
      width: 12.032rem;
      height: 9.728rem;
      background: rgb(35, 77, 211, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      .home-mileage-eighteen-title {
        font-size: 0.768rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.088rem;
        margin-top: 1.024rem;
        margin-bottom: 0.448rem;
      }
      .home-mileage-eighteen-content {
        font-size: 0.64rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.896rem;
      }
    }
    .home-mileage-twenty {
      width: 12.032rem;
      height: 8.32rem;
      background: rgb(0, 0, 0, 0.8);
      display: flex;
      flex-direction: column;
      // justify-content: space-evenly;
      align-items: center;
      .home-mileage-twenty-title {
        font-size: 0.768rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #234dd3;
        line-height: 1.088rem;
        margin-top: 1.92rem;
        margin-bottom: 0.64rem;
      }
      .home-mileage-twenty-content {
        font-size: 0.64rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.896rem;
      }
    }
    .home-mileage-twentyTwo {
      width: 12.032rem;
      height: 8.32rem;
      background: rgb(35, 77, 211, 0.8);
      display: flex;
      flex-direction: column;
      // justify-content: space-evenly;
      align-items: center;
      .home-mileage-twentyTwo-title {
        font-size: 0.768rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.088rem;
        margin-top: 1.92rem;
        margin-bottom: 0.64rem;
      }
      .home-mileage-twentyTwo-content {
        font-size: 0.64rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.896rem;
      }
    }
    .home-mileage-end {
      width: 12.032rem;
      height: 4.48rem;
      background: rgb(0, 0, 0, 0.8);
    }
  }
  .home-mileage-right {
    display: flex;
    flex-direction: column;
    .home-mileage-fifteen {
      width: 11.968rem;
      height: 7.04rem;
      background: rgb(35, 77, 211, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      .home-mileage-fifteen-title {
        font-size: 0.768rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.088rem;
        margin-top: 1.088rem;
        margin-bottom: 0.64rem;
      }
      .home-mileage-fifteen-content {
        font-size: 0.64rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.896rem;
      }
    }
    .home-mileage-seventeen {
      width: 11.968rem;
      height: 9.216rem;
      background: rgb(0, 0, 0, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      .home-mileage-seventeen-title {
        font-size: 0.768rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.088rem;
        margin-top: 1.024rem;
        margin-bottom: 0.64rem;
      }
      .home-mileage-seventeen-content {
        font-size: 0.64rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.896rem;
      }
    }
    .home-mileage-nineteen {
      width: 11.968rem;
      height: 9.344rem;
      background: rgb(35, 77, 211, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      .home-mileage-nineteen-title {
        font-size: 0.768rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.088rem;
        margin-top: 1.088rem;
        margin-bottom: 0.704rem;
      }
      .home-mileage-nineteen-content {
        font-size: 0.64rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.896rem;
      }
    }
    .home-mileage-twentyOne {
      width: 11.968rem;
      height: 9.344rem;
      background: rgb(0, 0, 0, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      .home-mileage-twentyOne-title {
        font-size: 0.768rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.088rem;
        margin-top: 1.024rem;
        margin-bottom: 0.704rem;
      }
      .home-mileage-twentyOne-content {
        font-size: 0.64rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.896rem;
      }
    }
    .home-mileage-twentyThree {
      width: 11.968rem;
      height: 7.29rem;
      background: rgb(35, 77, 211, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      .home-mileage-twentyThree-title {
        font-size: 0.768rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        line-height: 1.088rem;
        margin-top: 1.152rem;
        margin-bottom: 0.448rem;
      }
      .home-mileage-twentyThree-content {
        font-size: 0.64rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.896rem;
      }
    }
  }
}
.home-cooperate {
  width: 24rem;
  height: 24.32rem;
  background-image: url(../../../assets/img/hezuo.jpg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  .home-cooperate-title {
    font-size: 0.896rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #000000;
    line-height: 1.28rem;
    margin-top: 1.92rem;
    margin-bottom: 0.64rem;
  }
  .home-cooperate-line {
    width: 1.28rem;
    height: 0.128rem;
    background: #234dd3;
  }
}
.content-texts {
  z-index: 999;
  position: relative;
}
</style>
