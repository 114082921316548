<template>
  <div class="about">
    <div v-if="!$vuetify.breakpoint.mobile">
      <AboutPc></AboutPc>
    </div>
    <div v-if="$vuetify.breakpoint.mobile">
      <AboutMobile> </AboutMobile>
    </div>
  </div>
</template>

<script>
import AboutMobile from "@views/Mobile/AboutMobile/AboutMobile";
import AboutPc from "@views/Pc/AboutPc/AboutPc";

export default {
  components: {
    AboutMobile,
    AboutPc,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>
<style lang="scss" scoped></style>
